import React from 'react';
import Layout from '../components/layout';
import PageContainer from '../components/Containers/PageContainer';
import { makeStyles, Grid } from '@material-ui/core';
import COLORS from '../constants/colors';
import Header from '../components/Header';
import { FormattedMessage } from 'gatsby-plugin-intl';
import PageSection from '../components/Cards/PageSection';
import SIZES from '../constants/sizes';
import { graphql } from 'gatsby';
import { shape } from 'prop-types';
import SolutionCard from '../components/Cards/SolutionCard';
import SEO from '../components/seo';

const useStyles = makeStyles(theme => ({
  title: {
    color: COLORS.DARK_BLUE,
    paddingBottom: 40,
    paddingLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT,
    paddingRight: SIZES.PADDING.RIGHT_PADDING.DEFAULT,

    [theme.breakpoints.down('md')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.MD,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.MD,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.XS,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.XS,
    },
  },
  solutionsContainer: {
    marginTop: 100,
    marginBottom: 40,
    paddingLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT + 55,
    paddingRight: SIZES.PADDING.RIGHT_PADDING.DEFAULT,

    [theme.breakpoints.down('md')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.MD + 55,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.MD,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.XS,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.XS,
    },
    '&>*': {
      marginBottom: 50,
    },
  },
}));

const Solutions = ({ data }) => {
  const classes = useStyles();
  let isActualPagePostReversed = false;
  return (
    <Layout>
      <SEO
        title="Scanship | Solutions"
        description="Latest news from Scanship"
        keywords={['Solutions']}
      />
      <PageContainer>
        <Grid item xs={12}>
          <Header
            sub
            textTransform="lowerCase"
            className={classes.title}
          >
            <FormattedMessage id="solutions.title" />
          </Header>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {data.allContentfulScanshipPageSection.edges.map(
              (
                {
                  node: {
                    image: { fluid },
                    title,
                    description: { description },
                  },
                },
                i
              ) => {
                isActualPagePostReversed = !isActualPagePostReversed;
                return (
                  <PageSection
                    key={title}
                    imgFluid={fluid}
                    title={title && title}
                    description={description}
                    reversed={isActualPagePostReversed}
                    alignText={!title ? 'center' : null}
                    paddingTop={i !== 0}
                  />
                );
              }
            )}
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.solutionsContainer}
        >
          {data.allContentfulScanshipOrderedList.edges[0].node.items.map(
            ({
              solutionName,
              introText: { introText },
              externalLink,
              slug,
            }) => (
              <SolutionCard
                key={slug}
                title={solutionName}
                description={introText}
                externalLink={externalLink}
                slug={slug}
              />
            )
          )}
        </Grid>
      </PageContainer>
    </Layout>
  );
};

Solutions.propTypes = {
  data: shape(),
};

export default Solutions;

export const query = graphql`
  query SolutionsQuery {
    allContentfulScanshipPageSection(
      filter: { page: { eq: "/solutions" } }
      sort: { fields: createdAt, order: ASC }
    ) {
      edges {
        node {
          image {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          title
          description {
            description
          }
        }
      }
    }
    allContentfulScanshipOrderedList(
      filter: {
        listId: { eq: "/solutions/#solutions-list" }
      }
    ) {
      edges {
        node {
          items {
            ... on ContentfulScanshipSolution {
              solutionName
              introText {
                introText
              }
              externalLink
              slug
            }
          }
        }
      }
    }
  }
`;
