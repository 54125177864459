import React from 'react';
import {
  makeStyles,
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Img from 'gatsby-image';
import { shape, string, bool, element } from 'prop-types';
import FONTS from '../../constants/fonts';
import COLORS from '../../constants/colors';
import SIZES from '../../constants/sizes';

const useStyles = makeStyles(theme => ({
  PageSection: {
    color: COLORS.DARK_BLUE,
    justifyContent: 'space-between',
    paddingTop: ({ paddingTop }) => (paddingTop ? 98 : 0),
    paddingBottom: ({ paddingBottom }) =>
      paddingBottom ? 98 : 0,
    borderBottom: ({ paddingBottom }) =>
      paddingBottom ? `1px solid ${COLORS.GREY}` : 'none',
    [theme.breakpoints.up('xl')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT + 55,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.DEFAULT,
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: ({ reversed }) =>
        reversed && SIZES.PADDING.LEFT_PADDING.DEFAULT + 55,
      paddingRight: ({ reversed }) =>
        !reversed && SIZES.PADDING.RIGHT_PADDING.DEFAULT,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: ({ reversed }) =>
        reversed && SIZES.PADDING.LEFT_PADDING.MD + 55,
      paddingRight: ({ reversed }) =>
        !reversed && SIZES.PADDING.RIGHT_PADDING.MD,
    },

    [theme.breakpoints.down('xs')]: {
      paddingTop: ({ paddingTop }) => (paddingTop ? 60 : 0),
      paddingBottom: ({ paddingBottom }) =>
        paddingBottom ? 60 : 0,
      paddingLeft: `0 !important`,
      paddingRight: `0 !important`,
    },
    '&>div:nth-child(1)': {
      paddingRight: 50,
      [theme.breakpoints.down('md')]: { paddingRight: 20 },
      [theme.breakpoints.down('sm')]: { paddingRight: 10 },
      [theme.breakpoints.down('xs')]: { paddingRight: 0 },
    },
    '&>div:nth-child(2)': {
      paddingLeft: 50,
      [theme.breakpoints.down('md')]: { paddingLeft: 20 },
      [theme.breakpoints.down('sm')]: { paddingLeft: 10 },
      [theme.breakpoints.down('xs')]: { paddingLeft: 0 },
    },
  },
  mediaContainer: {
    width: 827,
    height: 'fit-content',
    '& >.gatsby-image-wrapper': {
      [theme.breakpoints.up('xl')]: { height: 450 },
      [theme.breakpoints.down('lg')]: { height: 350 },
      [theme.breakpoints.down('md')]: { height: 'auto' },
      [theme.breakpoints.down('xs')]: { height: 250 },
    },
  },
  textContainer: {
    maxWidth: 700,
    display: 'flex',
    alignItems: ({ alignText }) => alignText,
    [theme.breakpoints.down('xs')]: {
      marginTop: 40,
      paddingLeft: `${SIZES.PADDING.LEFT_PADDING.XS}px !important`,
      paddingRight: `${SIZES.PADDING.RIGHT_PADDING.XS}px !important`,
    },
    '&>div': {
      height: 'fit-content',
    },
  },
  title: {
    fontSize: FONTS.SIZE.M,
    fontWeight: FONTS.WEIGHT.BOLD,
    marginBottom: 24,
  },
  blueDot: {
    width: 8,
    height: 8,
    backgroundColor: COLORS.BLUE,
    display: 'inline-flex',
    alignSelf: 'center',
    transform: 'translateY(-50%)',
    marginLeft: 11,
  },
  description: {
    fontSize: FONTS.SIZE.XS,
    fontWeight: `${FONTS.WEIGHT.MEDIUM} !important`,
    whiteSpace: 'pre-line',
    height: 'fit-content',
    lineHeight: 1.5,
  },
}));

const PageSection = ({
  imgFluid,
  title,
  description,
  reversed,
  paddingBottom,
  paddingTop,
  customMedia,
  alignText,
  injectedElement,
}) => {
  const classes = useStyles({
    paddingBottom,
    paddingTop,
    reversed,
    alignText,
  });
  const theme = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down('xs')
  );

  const media = (
    <Grid
      item
      xs={12}
      sm={6}
      className={classes.mediaContainer}
    >
      {imgFluid ? (
        <Img fluid={imgFluid} alt={title} />
      ) : customMedia ? (
        customMedia
      ) : (
        <></>
      )}
    </Grid>
  );

  return (
    <Grid container className={classes.PageSection}>
      {(!reversed || smallScreen) && media}
      <Grid
        item
        xs={12}
        sm={6}
        className={classes.textContainer}
      >
        <Grid container>
          {title && (
            <Grid item xs={12} className={classes.title}>
              <h2>
                {title}
                <span className={classes.blueDot} />
              </h2>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            className={classes.description}
          >
            <p>{description}</p>
          </Grid>
          {injectedElement && (
            <Grid item xs={12}>
              {injectedElement}
            </Grid>
          )}
        </Grid>
      </Grid>
      {reversed && !smallScreen && media}
    </Grid>
  );
};

PageSection.propTypes = {
  imgFluid: shape(),
  title: string,
  description: string.isRequired,
  injectedElement: element,
  reversed: bool,
  paddingTop: bool,
  paddingBottom: bool,
  customMedia: element,
  alignText: string,
};

PageSection.defaultProps = {
  title: '',
  reversed: false,
  paddingTop: true,
  paddingBottom: true,
  alignText: '',
};

export default PageSection;
