import React from 'react';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { string } from 'prop-types';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles(theme => ({
  description: {
    maxWidth: 260,
    paddingBottom: 16,
    fontWeight: `${FONTS.WEIGHT.MEDIUM} !important`,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'none',
    },
  },
  line: {
    display: 'block',
    width: 30,
    height: '2px',
    border: 0,
    borderTop: `4px solid${COLORS.BLUE}`,
    margin: '0.5em 0',
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 15,
    },
  },

  readMore: {
    color: COLORS.BLUE,
    paddingTop: 6,
    paddingBottom: 20,
    textDecoration: 'none',
    fontWeight: FONTS.WEIGHT.MEDIUM,
  },
  externalLinkIcon: {
    fontSize: 15,
  },
  titleContainer: {
    maxWidth: 200,
    [theme.breakpoints.down('md')]: {
      maxWidth: 'auto',
    },
  },
}));

const SolutionCard = ({
  title,
  description,
  slug,
  externalLink,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={5}
          className={classes.titleContainer}
        >
          <h4>{title}</h4>
          <hr className={classes.line} />
        </Grid>
        <Grid item xs={12} sm={7}>
          <p className={classes.description}>
            {description}
          </p>
          {externalLink ? (
            <a
              href={externalLink}
              className={classes.readMore}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="SolutionCard.link" />
              <OpenInNewIcon
                className={classes.externalLinkIcon}
              />
            </a>
          ) : (
            <Link to={slug} className={classes.readMore}>
              <FormattedMessage id="SolutionCard.link" />
            </Link>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

SolutionCard.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  slug: string,
  externalLink: string,
};

export default SolutionCard;
